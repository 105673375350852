import _ from 'lodash';

const compareObjectsSize = <T extends Record<string, unknown>>(item1: T, item2: T) => {
  if (Object.keys(item1).length >= Object.keys(item2).length) {
    return {
      largerObject: item1,
      smallerObject: item2,
    };
  }

  return {
    largerObject: item2,
    smallerObject: item1,
  };
};
export const getDiffCount = <T extends Record<string, unknown>>(
  item1: T,
  item2: T,
  ignoreProperties: (keyof T)[] = []
) => {
  const copy1 = { ...item1 };
  const copy2 = { ...item2 };

  ignoreProperties.forEach((key) => {
    delete copy1[key];
    delete copy2[key];
  });

  const { largerObject, smallerObject } = compareObjectsSize(copy1, copy2);
  const diffCount = Object.entries(largerObject).filter(
    ([key, value]) => !_.isEqual(value, smallerObject[key])
  ).length;

  return diffCount;
};

export const isNullOrUndefined = (value: unknown) => {
  return value === null || value === undefined;
};

export const delay = (wait: number) => {
  return new Promise<void>((resolve) => {
    setTimeout(() => {
      resolve();
    }, wait);
  });
};
