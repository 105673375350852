import { isNullOrUndefined } from 'src/utils/utils';

export const getFormData = (data: Record<string, unknown>) => {
  const formData = new FormData();

  Object.entries(data).forEach(([key, value]) => {
    if (!isNullOrUndefined(value)) {
      formData.append(key, value as string | Blob);
    }
  });

  return formData;
};
