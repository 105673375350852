import { styled } from '@mui/material';
import {
  default as MuiIconButton,
  IconButtonProps as MuiIconButtonProps, IconButtonTypeMap
} from '@mui/material/IconButton';
import { ForwardedRef, MouseEventHandler, useMemo } from 'react';
import { Link, To } from 'react-router-dom';
import { typedForwardRef } from 'src/utils/types/forward-ref';
import { shouldNotForwardPropsWithKeys } from 'src/utils/types/should-not-forward-props';
import { Tooltip } from './Tooltip';

const StyledMuiIconButton = styled(MuiIconButton, {
  shouldForwardProp: shouldNotForwardPropsWithKeys<{ filled: boolean | undefined }>(['filled']),
})<{ filled: boolean | undefined }>(({ theme, filled }) => {
  if (filled)
    return {
      backgroundColor: theme.palette.primary.main,
      '&.Mui-disabled': {
        backgroundColor: theme.palette.action.disabled,
      },
      '.MuiIcon-root': {
        color: theme.palette.common.white,
      },
      '&:hover': {
        backgroundColor: theme.palette.primary.dark,
      },
    };
  return {};
});

export type IconButtonProps<D extends React.ElementType = IconButtonTypeMap['defaultComponent']> =
  MuiIconButtonProps<D, { component?: D }> & {
    tooltip?: string;
    filled?: boolean;
    buttonOrLink?: {
      isLink: boolean;
      onClick: MouseEventHandler<D> | undefined;
      to: To | undefined;
    };
  };

export const IconButtonInner = <
  D extends React.ElementType = IconButtonTypeMap['defaultComponent']
>(
  { filled, tooltip, buttonOrLink, ...props }: IconButtonProps<D>,
  ref: ForwardedRef<HTMLButtonElement>
) => {
  const rest = useMemo(() => {
    if (buttonOrLink) {
      if (buttonOrLink.isLink) {
        return { component: Link, to: buttonOrLink.to };
      } else {
        return { onClick: buttonOrLink.onClick };
      }
    }
    return {};
  }, [buttonOrLink]);

  const button = (
    <StyledMuiIconButton
      filled={filled}
      ref={ref}
      {...props}
      {...(rest as Partial<IconButtonProps<D>>)}
    ></StyledMuiIconButton>
  );
  return tooltip ? <Tooltip title={tooltip}>{button}</Tooltip> : button;
};

export const IconButton = typedForwardRef(IconButtonInner);
