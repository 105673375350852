import { createContext, ReactNode, useState } from 'react';
import { Link } from 'react-router-dom';
import { AppRoutes } from 'src/router/app-routes';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from 'src/ui-components';
import { PropsWithReactElement } from 'src/utils/types/props';

export type LoginPromptDialogData = {
  loginRedirectUrl?: string;
  text: ReactNode;
};
export const LoginRequiredDialogContext = createContext({
  showLoginRequiredDialog: (data: LoginPromptDialogData) => {
    return;
  },
});

export type LoginRequiredDialogProviderProps = PropsWithReactElement;

export const LoginRequiredDialogProvider = ({ children }: LoginRequiredDialogProviderProps) => {
  const [dialog, setDialog] = useState<LoginPromptDialogData | null>(null);

  const onClose = () => {
    setDialog(null);
  };

  const redirectUrl = dialog?.loginRedirectUrl ?? location.pathname + location.search;
  return (
    <>
      <LoginRequiredDialogContext.Provider
        value={{ showLoginRequiredDialog: (data) => setDialog(data) }}
      >
        {children}
      </LoginRequiredDialogContext.Provider>
      {dialog && (
        <Dialog open onClose={onClose} sx={{ textAlign: 'center' }}>
          <DialogTitle>Informacja</DialogTitle>
          <DialogContent>
            <DialogContentText>{dialog.text}</DialogContentText>
            <Box pt={3} pb={1} maxWidth='fit-content' mx='auto'>
              <Button
                size='large'
                component={Link}
                to={AppRoutes.Login({ redirectUrl })}
                fullWidth
                onClick={onClose}
              >
                Zaloguj się
              </Button>
              <DialogContentText my={2}>Lub</DialogContentText>
              <Button
                size='large'
                component={Link}
                to={AppRoutes.Register({ redirectUrl })}
                fullWidth
                onClick={onClose}
              >
                Utwórz darmowe konto
              </Button>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose} variant='text'>
              Zamknij
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};
