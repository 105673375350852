import { useEffect, useRef } from 'react';
import { FallbackProps } from 'react-error-boundary';
import { Link, useLocation } from 'react-router-dom';
import { usePushFrontendErrorLog } from 'src/queries/error-log-queries';
import { AppRoutes } from 'src/router/app-routes';
import { Button, Card, CardContent, Container, Icon, Typography } from 'src/ui-components';
import { fullMobileHeight } from 'src/utils/style-utils';

export type ErrorFallbackProps = FallbackProps;

export const ErrorFallback = ({ error, resetErrorBoundary }: ErrorFallbackProps) => {
  const { pushFrontendErrorLog } = usePushFrontendErrorLog();
  const navigatedToHomeRef = useRef(false);
  const location = useLocation();

  useEffect(() => {
    const err = error as Error;
    pushFrontendErrorLog({
      message: `RENDER_ERROR: message: ${err?.message ?? ''}`,
      stackTrace: err?.stack,
    });
  }, []);

  useEffect(() => {
    if (navigatedToHomeRef.current) resetErrorBoundary();
  }, [location]);

  return (
    <Container
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: fullMobileHeight,
      }}
    >
      <Card>
        <CardContent sx={{ textAlign: 'center' }}>
          <Icon fontSize='large' name='warning_amber' color='error' />
          <Typography fontSize={14} color='grey' my={1}>
            Coś poszło nie tak
            <br />
            Treść błędu: <b>{error?.message ?? ''}</b>
            <br /> Przepraszamy za utrudnienia
          </Typography>
          <Button variant='text' onClick={() => window.location.reload()}>
            Odśwież stronę
          </Button>
          <Button
            variant='text'
            component={Link}
            to={AppRoutes.Home()}
            onClick={() => (navigatedToHomeRef.current = true)}
          >
            Strona główna
          </Button>
        </CardContent>
      </Card>
    </Container>
  );
};
