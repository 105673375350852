import { Input, InputProps, OutlinedInput } from '@mui/material';
import { useEffect, useState } from 'react';
import { isNullOrUndefined } from 'src/utils/utils';

export type NumberInputProps = Omit<InputProps, 'value' | 'onChange'> & {
  value: unknown;
  isInteger?: boolean;
  isPositive?: boolean;
  onChange: (value: number | null) => void;
  variant: 'standard' | 'outlined';
};
export const NumberInput = ({
  value,
  isInteger,
  isPositive = true,
  onChange,
  variant,
  sx,
  ...props
}: NumberInputProps) => {
  const [val, setVal] = useState(value ?? '');

  useEffect(() => {
    if (isNullOrUndefined(value)) {
      setVal('');
    }
    // double != to do soft comparison and not add trailing zero if not necessary
    else if (typeof value === 'number' && !isNaN(value) && value != val) {
      setVal(value);
    }
  }, [value]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setVal(e.target.value);
    
    const valueAsNumber = +e.target.value;
    if (isNaN(valueAsNumber) || (isPositive && valueAsNumber < 0) || valueAsNumber === 0) {
      onChange(null);
    } else {
      onChange(isInteger ? +valueAsNumber.toFixed(0) : valueAsNumber);
    }
  };

  const inputProps = {
    value: val,
    onChange: handleChange,
    type: 'number',
    sx: {
      input: {
        // Chrome, Safari, Edge, Opera
        '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
          WebkitAppearance: 'none',
          margin: 0,
        },
        // Firefox
        MozAppearance: 'textfield',
      },
      ...sx,
    },
    ...props,
  } satisfies InputProps;

  if (variant === 'standard') {
    return <Input {...inputProps} />;
  } else {
    return <OutlinedInput size='small' {...inputProps} />;
  }
};
